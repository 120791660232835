var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('headerNav',{attrs:{"pathname":"关于我们"}}),_c('text-common',{attrs:{"title":"关于我们","img":_vm.img}}),_c('div',{staticClass:"about_us_bg"},[_c('div',{staticClass:"company_name"},[_vm._v("浙江中骁物流有限公司")]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"exhibition_bg"},_vm._l((_vm.statisticsList),function(item,index){return _c('div',{key:index,staticClass:"exhibition"},[(_vm.scroll < 750)?_c('v-countup',{staticClass:"title",attrs:{"start-value":"0","decimals":"","duration":"1","end-value":item.numericalValue,"options":{
            useEasing: true,
            useGrouping: true,
            separator: '',
            decimal: '.',
            prefix: '',
            suffix: '',
          }}}):_c('div',{staticStyle:{"height":"36px","width":"100%"}}),_c('div',[_vm._v(_vm._s(item.title))])],1)}),0),_c('img',{staticStyle:{"margin-bottom":"109px"},attrs:{"width":"800px","src":require("../assets/img/contenimg.png"),"alt":""}})]),_c('bottom-navigation')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company_pinyin"},[_c('span',{staticStyle:{"opacity":"0.25"}},[_vm._v("Zhejiang Zhongxiao Logistics.,Ltd")]),_c('div',{staticClass:"Underline"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_us_text_bg",staticStyle:{"line-height":"30px"}},[_c('div',{staticStyle:{"opacity":"0.85","font-weight":"100","text-align":"left","text-indent":"2em"}},[_c('span',[_vm._v("浙江中骁物流有限公司成立于2021年04月，注册资金1000万人民币，由上海驮龙物流有限公司全资持有，公司主营公路运输、仓储、城市配送等专业物流服务，致力于成为区域领先的多式联运与第三方物流企业。 公司以信息化和专业化运营为核心竞争力，整合上海、浙江、江苏、新疆、甘肃、西藏、四川、云南、贵阳等核心区域内高效的配送网络与卓越的公路干线运输，打造覆盖各主要核心产业聚集区的快捷物流服务。 公司始终以服务客户为中心，结合创新的IT技术、先进的行业理念，搭建信息化平台，优化运营模式，提升网络综合运营效率，降低运营成本，为客户提供安全、快捷、专业的公路运输及城市配送服务，助力客户创造更大价值。 中骁物流秉承“客户至上、不负重托”的服务理念，为客户创造价值。")])])])
}]

export { render, staticRenderFns }