<template>
  <div class="about">
    <headerNav pathname="关于我们" />
    <!-- <img class="banner" src="" alt /> -->
    <text-common
      title="关于我们"
      :img="img"
    />
    <!-- 公司介绍 -->
    <div class="about_us_bg">
      <div class="company_name">浙江中骁物流有限公司</div>
      <div class="company_pinyin">
        <span style="opacity: 0.25">Zhejiang Zhongxiao Logistics.,Ltd</span>
        <div class="Underline"></div>
      </div>

      <div
        class="about_us_text_bg"
        style="line-height: 30px"
      >
        <div style="opacity: 0.85; font-weight: 100;text-align:left;text-indent:2em">
          <span>浙江中骁物流有限公司成立于2021年04月，注册资金1000万人民币，由上海驮龙物流有限公司全资持有，公司主营公路运输、仓储、城市配送等专业物流服务，致力于成为区域领先的多式联运与第三方物流企业。
            公司以信息化和专业化运营为核心竞争力，整合上海、浙江、江苏、新疆、甘肃、西藏、四川、云南、贵阳等核心区域内高效的配送网络与卓越的公路干线运输，打造覆盖各主要核心产业聚集区的快捷物流服务。
            公司始终以服务客户为中心，结合创新的IT技术、先进的行业理念，搭建信息化平台，优化运营模式，提升网络综合运营效率，降低运营成本，为客户提供安全、快捷、专业的公路运输及城市配送服务，助力客户创造更大价值。 中骁物流秉承“客户至上、不负重托”的服务理念，为客户创造价值。</span>
        </div>
      </div>

      <!-- 滚动字体 -->
      <div class="exhibition_bg">
        <div
          class="exhibition"
          v-for="(item, index) in statisticsList"
          :key="index"
        >
          <v-countup
            v-if="scroll < 750"
            class="title"
            start-value="0"
            decimals=""
            duration="1"
            :end-value="item.numericalValue"
            :options="{
              useEasing: true,
              useGrouping: true,
              separator: '',
              decimal: '.',
              prefix: '',
              suffix: '',
            }"
          ></v-countup>
          <div
            v-else
            style="height: 36px; width: 100%"
          ></div>
          <div>{{ item.title }}</div>
        </div>
      </div>

      <img
        width="800px"
        style="margin-bottom: 109px"
        src="../assets/img/contenimg.png"
        alt=""
      />
    </div>
    <bottom-navigation />
  </div>
</template>

<script>
import Swiper from "swiper"; //引入swiper依赖
import "swiper/dist/css/swiper.min.css";
export default {
  data() {
    return {
      img: require("@/assets/about_us/about_us_banner.png"),
      bannerList: [
        {
          banner: require("@/assets/about_us/about_us_banner.png"),
        }
      ],
      statisticsList: [
        {
          numericalValue: "3000",
          title: "标准大型仓库"
        },
        {
          numericalValue: "6000",
          title: "免费为长期客户仓储的场地"
        },
        {
          numericalValue: "60",
          title: "2-30吨车辆"
        },
        {
          numericalValue: "200",
          title: "可调度车辆"
        },
      ],
      scroll: ''
    }
  },
  mounted() {
    this.getBanner(); //轮播
    window.addEventListener('scroll', this.menu)
  },
  methods: {

    menu() {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      //   console.log(this.scroll);
    },
    //封装轮播函数
    getBanner() {
      //调用延迟加载 $nextTick
      this.$nextTick(() => {
        var mySwiper = new Swiper(".swiper-container", {
          loop: true, // 循环模式选项
          autoplay: true, //自动切换
          //   virtualTranslate: true,
          //   autoplay: {
          //     delay: 1000,
          //   },
          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination"
          }
        });
      });

    },
  }

}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "DIN-Condensed";
  src: url(../assets/font/DIN-Condensed.ttf);
}
.about {
  .banner {
    width: 100%;
  }

  // 滚动字体样式
  .exhibition_bg {
    width: 1115px;
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 50px auto 59px auto;
    // padding: 0 300px;
    box-sizing: border-box;
    .exhibition {
      width: 25%;
      .title {
        font-size: 30px;
        font-weight: bold;
        border: none;
        font-family: "DIN-Condensed";
      }
      .title::after {
        content: "m²";
        font-size: 14px;
        color: #000000;
        display: inline-block;
        font-weight: normal;
        transform: translateY(-20px);
      }
    }
  }
  .exhibition:hover {
    color: #182d5d;
    .title::after {
      color: #182d5d;
    }
  }
  .exhibition_bg :nth-child(3) {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  .exhibition_bg :nth-child(1) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  .exhibition_bg :nth-child(3) span::after {
    content: "+" !important;
    font-size: 20px !important;
    border: none !important;
  }
  .exhibition_bg :nth-child(4) span::after {
    content: "+" !important;
    font-size: 20px !important;
  }

  .about_us_bg {
    text-align: center;
    width: 100%;
    margin-top: 95px;
    // padding: 96px 400px 109px 400px;
    box-sizing: border-box;
    font-family: PingFangSC-Medium;
    color: #000;
    .about_us_text_bg {
      width: 1110px;
      margin: 0 auto;
      font-weight: 100;
    }
    .company_name {
      color: #182d5d;
      font-size: 24px;
      font-weight: 500;
    }
    .company_pinyin {
      font-size: 14px;
      margin-bottom: 26px;
    }
    .Underline {
      width: 28px;
      height: 5px;
      background: #182d5d;
      margin: 5px auto;
    }
  }
}
</style>